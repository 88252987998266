import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Website van Bart Jan van Assen - Pixacle - KVK 59646047 - Esweg 15a - 7688RA - Daarle
        </p>
      </header>
    </div>
  );
}

export default App;
